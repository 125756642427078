<template>
<div class="mobile-page-layout">
        <div class="mobile-page-header" style="border-bottom: 1px solid #e6e6e6;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-text">
                        اطلس‌کشورها
                    </div>  
                </div>
                <div class="mobile-page-header-actions">  
                    <div class="header-actions-sw">
                        <div :class="'header-actions-sw-item ' + (typeList == 1 ? 'sw-item-active' : '')" @click="typeList = 1">فعلی</div>
                        <div :class="'header-actions-sw-item ' + (typeList == 2 ? 'sw-item-active' : '')" @click="typeList = 2">پیش‌بینی</div>
                    </div>
                </div>
            </div>
        </div>

    

        <div class="profile-mobile-view" style="overflow: unset;border-bottom: 1px solid #eaeaea;">
            <div :class="'country-box ' + (showCountryBox == true ? 'country-box-active' : '')">
                <div class="country-box-select" @click="showCountryBox = !showCountryBox">
                    <div :class="'country-box-select-flag mini-flag flag-'+countryBoxSelect.code"></div>
                    <span>{{countryBoxSelect.namefa}}</span>
                    <div class="country-box-select-arrow"><i class="uil uil-angle-down"></i></div>
                </div>
                <div class="country-box-content-bg" @click="showCountryBox = false"></div>
                <div class="country-box-content" @click="showCountryBox = true">
                    <div class="country-box-content-tabs">
                        <div :class="'country-box-content-tabs-item ' + (showCountryActiveTab == 'asia' ? 'active-tabs-item' : '')" @click="changeTab('asia');"><span>آسیا</span></div>
                        <div :class="'country-box-content-tabs-item ' + (showCountryActiveTab == 'europe' ? 'active-tabs-item' : '')" @click="changeTab('europe');"><span>اروپا</span></div>
                        <div :class="'country-box-content-tabs-item ' + (showCountryActiveTab == 'america' ? 'active-tabs-item' : '')" @click="changeTab('america');"><span>آمریکا</span></div>
                        <div :class="'country-box-content-tabs-item ' + (showCountryActiveTab == 'africa' ? 'active-tabs-item' : '')" @click="changeTab('africa');"><span>آفریقا</span></div>
                        <div :class="'country-box-content-tabs-item ' + (showCountryActiveTab == 'oceania' ? 'active-tabs-item' : '')" @click="changeTab('oceania');"><span>اقیانوسیه</span></div>
                    </div>
                    <div class="country-box-list">
                        <router-link v-for="(item, index) in CountryListActive" :class="(country.toLowerCase() == item.code.toLowerCase() ? 'active-country-box-list-item' : '')" :key="index" :to="{ name: 'EconomicsAtlas', params: { country : item.code.toLowerCase() },}">
                            <div class="country-box-list-item">
                                <div :class="'country-box-select-flag mini-flag flag-'+item.code.toLowerCase()"></div>
                                <span>{{item.namefa}}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>
       
        <div class="market-main-item" v-for="(item, index) in countryList" :key="index" v-if="countryList && !loading">
        
            <div class="market-main-item-row" style="background: #eaeaea;">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-title">
                        <h2 style="background: #666;padding: 4px 10px 4px;border-radius: 4px;color: #fff;font-size: 12px;">{{ item.indicator }}</h2>
                    </div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;direction: ltr;"> <i class="uil uil-clock" style="margin-right: 3px;"></i><span>{{ item.data.date.year +'/'+ item.data.date.day }}</span> </div>
                </div>
            </div>
            <div :class="'market-main-item-row ' + (item.value < 0 ? 'i-row-low' : '')"  v-if="typeList == 1">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-clock-eight"></i><span>آخرین </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{ formatPrice(item.value) }}</span> </div>
                </div>
            </div>
            <div :class="'market-main-item-row ' + (item.data.prev < 0 ? 'i-row-low' : '')"  v-if="item.data.prev && typeList == 1">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-clock-eight"></i><span>فعلی </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.prev)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.value < 0 ? 'i-row-low' : '')"  v-if="item.data.value && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-clock-eight"></i><span>فعلی </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.value)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q1 < 0 ? 'i-row-low' : '')"  v-if="item.data.q1 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌اول </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q1)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q2 < 0 ? 'i-row-low' : '')" v-if="item.data.q2 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌دوم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q2)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q3 < 0 ? 'i-row-low' : '')" v-if="item.data.q3 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌سوم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q3)}}</span></div>
                </div>
            </div>

            <div :class="'market-main-item-row ' + (item.data.q4 < 0 ? 'i-row-low' : '')"  v-if="item.data.q4 && typeList == 2">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-file"></i><span>فصل‌چهارم </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price" style="white-space: nowrap;display: block;"> <span>{{formatPrice(item.data.q4)}}</span></div>
                </div>
            </div>


            <div class="market-main-item-row" v-if="item.data.metric">
                <div class="market-main-item-col item-col-right">
                    <div class="market-main-item-time"><i class="uil uil-calculator-alt"></i><span> واحد اندازه گیری </span></div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-change" style="white-space: nowrap;display: block;">{{ item.data.metric}}</div>
                </div>
            </div>

        </div>
    </div>

</template>


<script>
import moment from "moment-jalaali";
import static_country from "@/assets/static_country";

export default {
    name: 'AtlasView',
    component: {
    },
    data: function () {
        return {
            countryList: {},
            static_country,
            selectCountryList:  { country: 'foreign-exchange-reserves', name: 'ذخایر ارزی خارجی'},
            country: this.$route.params.country ?? 'in',
            loading: false,
            countryBoxSelect: {name:'india',namefa:'هند',code:'in'},
            showCountryBox: false,
            showCountryActiveTab: 'asia',
            CountryListActive: {},
            typeList: 1,
            searchQuery:'',
            search:false,
            items: [
                { country: 'foreign-exchange-reserves', name: 'ذخایر ارزی خارجی'},
                { country: 'loans-to-private-sector', name: 'وام به بخش خصوصی'},
                { country: 'bankruptcies', name: 'ورشکستگی'},
                { country: 'capital-flows', name: 'گردش سرمایه'},
                { country: 'current-account', name: 'حساب جاری'},
                { country: 'external-debt', name: 'بدهی خارجی'},
                { country: 'gold-reserves', name: 'ذخایر طلا'},
                { country: 'remittances', name: 'حواله'},
                { country: 'construction-output', name: 'میزان ساخت و ساز'},
                { country: 'gasoline-prices', name: 'قیمت گازوئیل'},
                { country: 'business-confidence', name: 'اطمینان کسب و کار'},
                { country: 'consumer-confidence', name: 'ضریب اطمینان مصرف کننده'},
                { country: 'productivity', name: 'بهره وری'},
                { country: 'wages', name: 'دستمزد'},
                { country: 'interest-rate', name: 'نرخ بهره'},
                { country: 'bank-lending-rate', name: 'نرخ وام بانکی'},
                { country: 'zew-economic-sentiment-index', name: 'شاخص تمایلات اقتصادی توسط موسسه ZEW'},
                { country: 'crude-oil-production', name: 'تولید نفت خام'},
                { country: 'exports', name: 'صادرات'},
                { country: 'foreign-direct-investment', name: 'سرمایه گذاری مستقیم خارجی'},
                { country: 'imports', name: 'واردات'},
                { country: 'gdp', name: 'تولید ناخالص داخلی'},
                { country: 'inflation-rate', name: 'نرخ تورم'},
                { country: 'food-inflation', name: 'تورم مواد غذایی'},
                { country: 'ease-of-doing-business', name: 'آسانی کسب و کار'},
                { country: 'population', name: 'جمعیت'},
                { country: 'unemployment-rate', name: 'نرخ بیکاری'},
                { country: 'rating', name: 'ارزیابی اعتبار'},
            ],
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
        this.countryBoxSelect = {name: this.static_country.country_name[this.country.toUpperCase()].en, namefa:this.static_country.country_name[this.country.toUpperCase()].fa, code: this.country}; 
        this.changeTab(this.static_country.country_locations.find(x => x.code === this.country.toUpperCase()).continent);
    },
    computed: {
        filteredList() {
            if(this.searchQuery === '') {
                return this.localCryptoExchanges;
            }
            return Object.values(this.localCryptoExchanges).filter(Exchange => {
                return Exchange.category_title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    methods: {
        changeTab(param){
            this.showCountryActiveTab = param;
            this.CountryListActive = {};
            this.static_country.country_locations.find((o, i) => {
                if (o.continent === param) {
                    if(this.static_country.country_name[o.code.toUpperCase()]){
                        this.CountryListActive[i] = { name: o.name, namefa: this.static_country.country_name[o.code.toUpperCase()].fa , code: o.code};
                    }
                }
            });
        },
        toggle() {
            this.country = this.selectCountryList.country;
            this.loadData();
        },
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        loadData() {
            this.loading = true;
            this.$helpers.makeRequest('POST', '/economics/atlas', {
                country : this.country,
            }).then(api_response => {
                if ( api_response.status == 200 ) {
                    this.countryList = JSON.parse(api_response.data).response;
                    this.loading = false;
                }
            });
        }
    }
}
</script>
